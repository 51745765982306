<template>
    <el-container>
        <el-header>
            <el-menu
                    :default-active="activeIndex"
                    class="el-menu-demo"
                    mode="horizontal"
                    background-color="#09253b"
                    text-color="#fff"
                    active-text-color="#ffd04b"
                    @select="handleSelect"
                    style="--el-menu-border-color:'#ffffff';"
            >
                <el-menu-item index="0"><img src="./assets/banner.png" /></el-menu-item>
                <el-menu-item index="1">首页</el-menu-item>
                <el-sub-menu index="2">
                    <template #title>产品介绍</template>
                    <el-menu-item index="2-1">产品功能</el-menu-item>
                    <el-menu-item index="2-2">版本历史</el-menu-item>
                </el-sub-menu>
                <el-sub-menu index="3">
                    <template #title>产品服务</template>
                    <el-menu-item index="3-1">服务介绍</el-menu-item>
                    <el-menu-item index="3-2">售后信息</el-menu-item>
                </el-sub-menu>
                <el-menu-item index="4">软件下载</el-menu-item>
                <el-menu-item index="5">公司简介</el-menu-item>
            </el-menu>
        </el-header>
        <el-main>
            <img src="./assets/main1.png" usemap="#bs_download" />
            <map name="bs_download" id="bs_download">
                <area shape="rect" coords="685,720,975,877" href="https://s7dwgvup0g.feishu.cn/docx/Z52CdJpQIoQx9sxxXLncZ4GBnFg" target="_blank" alt="免费试用">
                <area shape="rect" coords="1000,720,1290,877" href="https://yhz1820.oss-cn-beijing.aliyuncs.com/bulletspread.zip" target="_blank" alt="win版点击下载">
                <area shape="rect" coords="1308,720,1598,877" href="https://yhz1820.oss-cn-beijing.aliyuncs.com/bulletspread_mac.dmg" target="_blank" alt="mac版点击下载">
            </map>
            <img src="./assets/main2.png" />
        </el-main>
        <el-footer>
            <div><el-text style="color:#008fff;">湖州织里尚钊网络科技有限公司</el-text></div>
            <div><el-link style="color:#008fff;" href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2022028188号-2</el-link></div>
        </el-footer>
    </el-container>
</template>

<script>

export default {
    data() {
        return {
            activeIndex: '1'
        };
    },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        }
    },
    name: 'App',
    components: {}
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
</style>
